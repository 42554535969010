import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ElModule } from 'element-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { TimeagoModule } from 'ngx-timeago';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { RendererFactory2 } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OtherUsersComponent } from './components/other-users/other-users.component';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';

const trackingCode = environment.trackingCode;

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, OtherUsersComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ElModule.forRoot(),
    JwtModule.forRoot({
      config: {},
    }),
    NgxSpinnerModule,
    NgxPaginationModule,
    DataTablesModule,
    NgxGoogleAnalyticsModule.forRoot(trackingCode),
    TimeagoModule.forRoot(),
    NgxSkeletonLoaderModule.forRoot({
      animation: 'pulse',
      loadingText: 'This item is actually loading...',
    }),
    BsDatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CarouselModule.forRoot(),
    ButtonModule,
    NgxQRCodeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      // useValue: Sentry.createErrorHandler({
      //   showDialog: true,
      // }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    BsDatepickerConfig,
    // RendererFactory2
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
